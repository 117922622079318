/*
 * @Author: ChenYaJin
 * @Date: 2023-07-06 16:41:44
 * @LastEditors: ChenYaJin
 * @LastEditTime: 2023-11-20 16:01:24
 * @Description: 项目模型
 */
import { useOptionStore } from '~/stores/modules/option'
export interface IProject {
  enterpriseId?: string
  id?: string
  name?: string
  type?: string // 项目性质一级
  belowType?: string //  项目性质二级
  belowTypeName?: string
  way?: string // 项目方式一级
  belowWay?: string[] // 项目方式二级
  supportability?: boolean | number // 是否是保障性住房
  insured?: boolean | number // 公共部位共用设备公众责任保险是否参保
  annualReceivable?: number // 年度物业服务费应收总额
  amateurIncome?: number // 多种经营年收入
  contractStartAt?: string
  address?: string
  elevatorsTotal?: string | number
  contractEndAt?: string
  extent?: string | number
  housesTotal?: string | number
  startingAt?: string
  staffTotal?: string | number
  auditStatus?: string
  score?: string
  reject?: string
  reviewBy?: string
  contract?: string[] | string
  districtCode?: string
  cityCode?: string
  provinceCode?: string
  reviewAt?: string
  deadlineAt?: string
  ongoingActivities?: number
}

export class Project implements IProject {
  enterpriseId?: string
  id?: string
  name?: string
  type?: string
  belowType?: string
  belowTypeName?: string
  way?: string
  belowWay?: string[]
  supportability?: boolean | number
  insured?: boolean | number
  annualReceivable?: number
  amateurIncome?: number
  contractStartAt?: string
  address?: string
  elevatorsTotal?: number | string
  contractEndAt?: string
  extent?: number | string
  housesTotal?: number | string
  startingAt?: string
  staffTotal?: number | string
  auditStatus?: string
  score?: string
  reject?: string
  reviewBy?: string
  contract?: string[] | string
  districtCode?: string
  cityCode?: string
  provinceCode?: string
  reviewAt?: string
  deadlineAt?: string
  ongoingActivities?: number

  constructor(json: IProject = {}) {
    this.enterpriseId = json.enterpriseId || ''
    this.id = json.id || ''
    this.name = json.name || ''
    this.type = json.type || ''
    this.contractStartAt = json.contractStartAt || ''
    this.address = json.address || ''
    this.elevatorsTotal = json.elevatorsTotal || ''
    this.contractEndAt = json.contractEndAt || ''
    this.extent = json.extent || ''
    this.housesTotal = json.housesTotal || ''
    this.startingAt = json.startingAt || ''
    this.staffTotal = json.staffTotal || ''
    this.auditStatus = json.auditStatus || ''
    this.score = json.score || ''
    this.reject = json.reject || ''
    this.reviewBy = json.reviewBy || ''
    this.contract = json.contract || []
    this.districtCode = json.districtCode || ''
    this.cityCode = json.cityCode || ''
    this.provinceCode = json.provinceCode || ''
    this.belowType = json.belowType
    this.belowTypeName = json.belowTypeName
    this.way = json.way
    this.belowWay = json.belowWay || []
    this.supportability = Boolean(json.supportability)
    this.insured = Boolean(json.insured)
    this.annualReceivable = json.annualReceivable
    this.amateurIncome = json.amateurIncome
    this.reviewAt = json.reviewAt
    this.deadlineAt = json.deadlineAt
    this.ongoingActivities = json.ongoingActivities
  }

  get auditStatusText() {
    const optionStore = useOptionStore()
    return optionStore.getStatusName(this.auditStatus || '')
  }

  get typeText() {
    const optionStore = useOptionStore()
    return optionStore.getProjectTypeName(this.type || '')
  }
}
